/**
 * @module Notifications
 */

/**
 * Lists possible semantic use-cases for notifications, which can alter how the notification is presented to the user.
 *
 * Additional indicator types may be added in future releases.
 */
export enum IndicatorType {
    FAILURE = 'failure',
    WARNING = 'warning',
    SUCCESS = 'success'
}

export interface NotificationIndicator {
    /**
     * Indicates the semantic intent behind the indicator - this determines the visual styling of the indicator when
     * seen by the user.
     *
     * For example - an indicator could be used to:
     * - `IndicatorType.FAILURE`: Indicate a failure has occurred from an action the user has taken.
     * - `IndicatorType.WARNING`: Warn a user that they have a limited amount of time to buy.
     * - `IndicatorType.SUCCESS`: Inform the user that an item has been successfully ordered.
     */
    type: IndicatorType;

    /**
     * Customized text to be displayed in the indicator. When set, this will override the default indicator text.
     * Text is limited to 32 characters including spaces.
     */
    text?: string;
}
