/**
 * Feeds allow users to opt-in to receiving push notifications from applications or organizations. The APIs here allow
 * applications to query or request changes to the state of the users current subscriptions.
 *
 * This functionality is only available if the user is currently signed-in with an OpenFin ID.
 *
 * @module Feeds
 */

/**
 * Need a comment block here so that the comment block above is interpreted as a file comment, and not a comment on the
 * import below.
 *
 * @hidden
 */
import {tryServiceDispatch} from './connection';
import {APITopic} from './internal';

/**
 * Checks if the currently logged in user is subscribed to the given feed.
 * @param feedId The ID of the feed to query
 *
 * @throws If there is no user currently logged in
 * @throws If the given feedId does not map to a known feed
 * @throws If the {@link getStatus|version} of the provider version is insufficient
 * @since 0.12.3
 */
export async function isSubscribed(feedId: string): Promise<boolean> {
    return tryServiceDispatch(APITopic.IS_SUBSCRIBED, feedId).catch((error) => {
        if (error?.message === 'No action registered') {
            throw new Error(`Cannot query subscription to ${feedId || 'feed'}. Current provider version does not support this API.`);
        }

        throw error;
    });
}

/**
 * Creates a notification prompting the user to subscribe to the given feed.
 * @param feedId The ID of the feed to query
 *
 * @throws If there is no user currently logged in
 * @throws If the given feedId does not map to a known feed
 * @throws If the {@link getStatus|version} of the provider version is insufficient
 * @since 0.12.3
 */
export async function requestSubscribe(feedId: string): Promise<void> {
    return tryServiceDispatch(APITopic.REQUEST_SUBSCRIBE, feedId).catch((error) => {
        if (error?.message === 'No action registered') {
            throw new Error(`Cannot request subscription to ${feedId || 'feed'}. Current provider version does not support this API.`);
        }

        throw error;
    });
}
